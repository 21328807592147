import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Quote from "../components/quote"


class quotesPage extends React.Component {
  render() {
    return (
    <Layout>
      <SEO title="Quotes" />
      <section className="info">
        <div className="infowrap">
          <div className="info-text">
            <Quote 
            text="Everyone seems to have a clear idea of how other people should lead their lives, but none about his or her own." 
            author="Paulo Coelho, The Alchemist" />

            <Quote 
            text="Your primary role should be to share what you know, not to tell people how things should be done." 
            author="Steve Krug" />

            <Quote 
            text="He who is not busy being born is busy dying." 
            author="Bob Dylan" />

            <Quote 
            text="My success is determined by how I handle what I don't know, not what I already know." 
            author="Ray Dalio" />

            <Quote 
            text="Everything we hear is an opinion, not a fact. Everything we see is a perspective, not the truth." 
            author="Marcus Aurelius" />

            <Quote 
            text="Price is what you pay. Value is what you get. " 
            author="Warren Buffet" />

            <Quote 
            text="The life you live is a result of the habits you develop." 
            author="Ray Dalio" />

            <Quote 
            text="If you cave in today, you're twice as likely to cave in tomorrow." 
            author="Steven Pressfield" />

            <Quote 
            text="Less, but better." 
            author="Dieter Rams" />

            <Quote 
            text="Get rid of everything that is not essential to making a point." 
            author="Christoph Nieman" />

            <Quote 
            text="Do good work for good people." 
            author="Aaron Draplin" />

            <Quote 
            text="If you think good design is expensive, you should look at the cost of bad design." 
            author="Ralf Speth" />

            <Quote 
            text="If you’re not prepared to be wrong, you’ll never come up with anything original." 
            author="Sir Ken Robinson" />
          </div>
        </div>
      </section>
    </Layout>
    )
    }
  }


export default quotesPage